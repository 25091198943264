import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import { Link } from "gatsby";
import ButtonIcon from "../components/Button/ButtonIcon";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Mortgage Rate Options | Right Start | Mortgage Lender"
        description="Learn about your mortgage rate options, such as fixed rate, ARM, interest only, and graduated payments. The choice is yours when applying for a home loan."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pb-10 pt-16 md:pb-12 md:pt-20">
        <div className="mb-20 md:mb-28">
          <div className="container">
            <header className="mx-auto mb-16 max-w-[640px] text-center md:mb-20">
              <h1>Mortgage Rate Options</h1>
              <p className="md:text-xl">
                You have a choice when it comes to how you want to pay the
                principal and interest on your home loan.
              </p>
            </header>

            <div className="grid gap-y-10 md:grid-cols-2 md:gap-x-6 lg:gap-x-10">
              <Link
                to="/fixed-rate-mortgage/"
                className="group block overflow-hidden rounded-lg bg-white px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
              >
                <h2 className="heading-four mb-3">Fixed Rate</h2>
                <p>
                  You pay a set interest rate for the lifetime of the loan, and
                  your monthly payment is always the same.
                </p>
                <ButtonIcon
                  href="/fixed-rate-mortgage/"
                  text="Learn More"
                  altStyle={2}
                />
              </Link>

              <Link
                to="/adjustable-rate-mortgage/"
                className="group block overflow-hidden rounded-lg bg-white px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
              >
                <h2 className="heading-four mb-3">Adjustable-Rate Mortgage</h2>
                <p>
                  The interest rate on your loan will adjust based on the market
                  and your monthly payment will periodically change.
                </p>
                <ButtonIcon
                  href="/adjustable-rate-mortgage/"
                  text="Learn More"
                  altStyle={2}
                />
              </Link>

              <Link
                to="/interest-only-mortgage/"
                className="group block overflow-hidden rounded-lg bg-white px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
              >
                <h2 className="heading-four mb-3">Interest Only</h2>
                <p>
                  You pay interest for the first several years of the loan,
                  giving you a lower monthly payment during that period.
                </p>
                <ButtonIcon
                  href="/interest-only-mortgage/"
                  text="Learn More"
                  altStyle={2}
                />
              </Link>

              <Link
                to="/graduated-payment-mortgage/"
                className="group block overflow-hidden rounded-lg bg-white px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
              >
                <h2 className="heading-four mb-3">Graduated Payments</h2>
                <p>
                  Your monthly payment gradually increases over time, allowing
                  you to start off with a lower monthly mortgage.
                </p>
                <ButtonIcon
                  href="/graduated-payment-mortgage/"
                  text="Learn More"
                  altStyle={2}
                />
              </Link>
            </div>
          </div>
        </div>
        <CallToAction className="!mb-0" />
      </section>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
